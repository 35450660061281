<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showOutput="true" @getList="getList"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable placeholder="兑换手机号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.from_name" size="small" clearable placeholder="兑换来源"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.viptype" size="small" placeholder="请选择兑换类型" clearable>
					<el-option v-for="(item,k) in $vipType" :key="k" :label="item" :value="k"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.city" size="small" placeholder="请选择城市" clearable>
					<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="k"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker size="small" v-model="searchFormData.dates" type="daterange" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="phone" title="用户信息" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="type" title="类型" align="center" min-width="100px">
				<template v-slot="{ row }">
					{{ $vipType[row.viptype] }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="city" title="所在城市" align="center" min-width="80px">
				<template v-slot="{ row }">
					{{ $userArea[row.city] }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="electric" title="兑换当天充电量" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="from_name" title="兑换来源" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="ctime" title="时间" align="center" min-width="80px" />
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'vip-record-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '会员权益1日志',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				// 用户搜索
				sLoading: false
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true;
				this.searchFormData.id = this.$route.query.id;
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Vip.VipcfgBoxLog(params)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
